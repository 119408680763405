<template>
  <div class="taskDrawer">
      <addTaskType :show="showTaskType" :editData="editTypes" @edit="editTaskSet"  @close="(val)=>{showTaskType=val}" @submit="addTaskSet"></addTaskType>
      <div class="clearfix">
          <span class="title csP" @click="$router.push('/home/task')"><a-icon type="left" style="color:#6a87e6;margin-right:10px"/>{{$route.query.id?'编辑任务':'添加任务'}}</span>
      </div>
      <div style="padding-top:20px">
        <a-form layout="vertical" hideRequiredMark>
          <div class="inputBox" v-if="!$route.query.id">
            <span class="point"></span>
            <div class="inputWrap">
              <a-form-item>
                <a-cascader :fieldNames="{ label: 'cname', value: 'id', children: 'son' }" :defaultValue="defaultValue" :options="options" @change="onChange" placeholder="请选择试卷分类" class="noBorder"/>
              </a-form-item>
            </div>
          </div>
          <div class="flex2">
            <div class="inputBox">
              <span class="point"></span>
              <div class="inputWrap">
                <a-form-item>
                  <a-input placeholder="输入大标题" class="noBorder" v-model="add.title"/>
                </a-form-item>
              </div>
            </div>
            <div >
              <a-checkbox @change="useModel" v-if="modelData" style="margin-left: 30px">
                使用模板
              </a-checkbox>
            </div>
          </div>
          <div class="inputBox">
            <span class="point"></span>
            <div class="inputWrap">
              <a-form-item>
                <a-upload
                name="file"
                @change="changeUpload"
                :customRequest="customRequest"
                :fileList='fileList'
                class="noBorder"
              >
                <a-button>上传附件</a-button>
              </a-upload>
              </a-form-item>
            </div>
          </div>
          <div class="inputBox" v-if="fileList.length">
            <span class="point"></span>
            <div class="inputWrap">
              <a-form-item>
                <a-input placeholder="编辑者注意事项" class="noBorder" v-model="add.creater_care"/>
              </a-form-item>
            </div>
          </div>
          <div class="inputBox">
            <span class="point"></span>
            <span style="margin-left:50px">题号排序方式</span>
            <div class="inputWrap">
              <a-radio-group name="radioGroup" v-model="add.sort_type">
                <a-radio :value="2">按大题排序</a-radio>
                <a-radio :value="1">按试卷排序</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="inputBox">
            <span class="point"></span>
            <div class="inputWrap">
              <a-form-item>
                <a-textarea :rows="3" placeholder="试卷注意事项" class="noBorder" v-model="add.paper_care"/>
              </a-form-item>
            </div>
          </div>
          <div class="inputBox grade">
            <div class="gradeSet"><div><span class="point"></span><span class="title">分值设置</span></div></div>
            <div class="pl35x" style="margin-bottom:20px">
              <div class="gradeList" style="margin-right: 50px;">
                <span class="title" style="width:60px">总分</span>
                <div class="inputWrap" style="border:none;padding:0">
                  <a-input
                    :value="add.total_score"
                    :maxLength="25"
                    style="width: 120px"
                    @change="add.total_score=numDetector($event)"
                    class="hasText"
                  />
                  <div class="rightTest w30x">分</div>
                </div>
              </div>
              <div class="gradeList" style="margin-right: 50px;">
                <span class="title" style="width:60px">总题量</span>
                <div class="inputWrap" style="border:none;padding:0">
                  <a-input
                    :value="add.total_count"
                    :maxLength="25"
                    style="width: 120px"
                    @change="add.total_count=numDetector($event)"
                    class="hasText"
                  />
                  <div class="rightTest w30x">题</div>
                </div>
              </div>
              <div class="gradeList" style="margin-right: 50px;">
                <span class="title" style="width:60px">时间</span>
                <div class="inputWrap" style="border:none;padding:0">
                  <a-input
                    :value="add.limit_time"
                    :maxLength="25"
                     @change="add.limit_time=numDetector($event)"
                    style="width: 120px"
                    class="hasText"
                  />
                  <div class="rightTest w30x">分</div>
                </div>
              </div>
            </div>
            <div class="gradeSet"><div><span class="point"></span><span class="title">题型设置</span></div><button class="btn border addtaskType" @click="showTaskType=true ; editTypes = null">新增</button></div>
            <div v-for="(item, index) in task_types" :key="index">
              <template  v-if="item.type != 'material'">
                <div style="margin:20px 0" class="clearfix">
                  {{num_zn[index]}}、{{item.title}}<span style="margin-left:10px">({{item.describe}})</span>
                  <div class="fR csP_E">
                    <span style="color: #5180e0;margin-right:10px;font-size:16px" @click="Edit(item,index)">编辑</span>
                    <a-popconfirm
                        title="确定删除?"
                        @confirm="task_types.splice(index,1)"
                        okText="确定"
                        cancelText="取消"
                      >
                        <span style="color: #5180e0;font-size:16px">删除</span>
                    </a-popconfirm>
                  </div>
                </div>
                <div class="pl35x mb20x">
                  <div class="gradeList">
                    <span class="title">{{taskType(item.type)}}</span>
                    <!-- <span class="title">{{item.title}}</span> -->
                    <div class="inputWrap">
                      <span>{{item.num}}</span>
                      <div class="rightTest">题</div>
                    </div>
                  </div>
                  <div class="gradeList">
                    <div class="inputWrap">
                      <span>{{item.each_score}}</span>
                      <div class="rightTest">分/题</div>
                    </div>
                  </div>
                  <div class="gradeList mR0">
                    <div class="inputWrap">
                      <span>{{item.answerNum ? item.answerNum * item.each_score :item.num*item.each_score}}</span>
                      <div class="rightTest">分</div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div style="margin:20px 0" class="clearfix">
                  {{num_zn[index]}}、{{item.title}}<span style="margin-left:10px">({{item.describe}})</span>
                  <div class="fR csP_E">
                    <span style="color: #5180e0;margin-right:10px;font-size:16px" @click="Edit(item,index)">编辑</span>
                    <a-popconfirm
                        title="确定删除?"
                        @confirm="task_types.splice(index,1)"
                        okText="确定"
                        cancelText="取消"
                      >
                        <span style="color: #5180e0;font-size:16px">删除</span>
                    </a-popconfirm>
                  </div>
                </div>
                <a-row>
                  <a-col :span="4"><span class="title">{{taskType(item.type)}}</span></a-col>
                  <a-col :span="20">
                    <div class="pl35x mb20x" v-for="(el,mIndex) in item.materialTask" :key="mIndex">
                      <div class="gradeList">
                        <span class="title">{{taskType(el.type)}}</span>
                        <div class="inputWrap">
                          <span>{{el.num}}</span>
                          <div class="rightTest">题</div>
                        </div>
                      </div>
                      <div class="gradeList">
                        <div class="inputWrap">
                          <span>{{el.each_score}}</span>
                          <div class="rightTest">分/题</div>
                        </div>
                      </div>
                      <div class="gradeList mR0">
                        <div class="inputWrap">
                          <span>{{ el.num*el.each_score}}</span>
                          <div class="rightTest">分</div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </template>
            </div>
          </div>
          <div>
          <a-checkbox  v-model="saveModel">
              保存模板
          </a-checkbox>
        </div>
        <div style="text-align:center">
          <a-button class="btn" @click="submit" type="primary" style="width:120px">确定</a-button>
        </div>
        </a-form>
        
      </div>
  </div>
</template>
<script>
import { num_zn, taskType } from '@/libs/transform'
import addTaskType  from '@/components/addTaskType'
import { getCate, addTask, uploadImg, getModelDetail, addModel, getSimpleTask, editTask } from '@/libs/api'
export default {
    name:'addTask',
    components:{ addTaskType },
    data() {
      return {
        num_zn,
        form: this.$form.createForm(this),
        showTaskType:false,
        modelData: null,
        add:{
          cate_id: '',
          title: '',
          creater_care: '',
          paper_care: '',
          total_score:'',
          total_count:'',
          sort_type:2,
          limit_time:''
        },
        editTypes:null,
        currentEditIndex:'',
        task_types:[],
        options: [],
        fileList:[],
        fileUrl:'',
        defaultValue:[],
        saveModel: false
      };
    },
    computed:{
    },
    mounted(){
      this.GetCate()
      if(this.$route.query.id){
        getSimpleTask({id: this.$route.query.id}).then(res =>{
          this.add = {
            cate_id: res.cate_id,
            title: res.title,
            creater_care: res.creater_care,
            paper_care: res.paper_care,
            total_score: res.total_score,
            total_count: res.total_count,
            sort_type: res.sort_type,
            limit_time: res.limit_time,
            id: res.id
          }
          this.task_types = JSON.parse(res.task_types)
        })
      }
    },
    methods: {
      taskType,
      reset(){
        this.defaultValue = []
        this.fileList = []
        this.add={
          cate_id: '',
          title: '',
          creater_care: '',
          paper_care: '',
          total_score:'',
          total_count:'',
          sort_type:1,
          limit_time:''
        }
      },
      getCateArr(item){
        item.forEach(el => {
            if(el.son.length){
              this.getCateArr(el.son)
            }else{
              delete el.son
            }
        });
      },
      computedNum( val1, val2 ){
        if( val1 && val2 ){
          return val1*val2
        }else{
          return null
        }
      },
      changeUpload(info){
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileList = fileList;
      },
      customRequest(data){
        let formData = new FormData()
        formData.append('file',data.file)
        uploadImg(formData).then(res=>{
          if(res){
            this.fileList[0].status='done'
            this.add.file_name = this.fileList[0].name
            this.add.file_id = this.fileList[0].uid
            this.fileUrl=res.fileUrl
          }
        },error=>{
          console.log(error)
        })
      },
      GetCate(){
        return new Promise((resolve,reject)=>{
          getCate().then(res=>{
            if(res.length){
              this.options=res
              this.getCateArr(this.options)
              resolve()
            }
          },error=>{
            console.log(error)
            reject()
          })
        })
      },
      onClose() {
          this.$emit('Close')
      },
      onChange(id){
        this.add.cate_id=id[id.length-1]
        getModelDetail({cate_id:id[id.length-1]}).then(res =>{
          this.modelData = res
        }).catch(error=>{
          console.log(error)
        })
      },
      numDetector(e){
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
          return value
        }
      },
      addTaskSet(val){
        this.task_types.push(val)
      },
      editTaskSet(val){
        this.task_types[this.currentEditIndex] = val
      },
      submit(){
        if(!this.add.cate_id){
          this.$message.error('请选择学科分类')
          return false
        }
        if(!this.add.title){
          this.$message.error('标题不能为空')
          return false
        }
        // if(!this.add.creater_care){
        //   this.$message.error('编辑者注意事项不能为空')
        //   return false
        // }
        if(!this.add.paper_care){
          this.$message.error('试卷注意事项不能为空')
          return false
        }
        // if(!this.fileList.length){
        //   this.$message.error('请上传附件')
        //   return false
        // }
        if(!this.add.total_score){
          this.$message.error('总分不能为空')
          return false
        }
        if(!this.add.total_count){
          this.$message.error('总题量不能为空')
          return false
        }
        if(!this.add.limit_time){
          this.$message.error('时间不能为空')
          return false
        }
        let formData = new FormData()
        // formData.append('user_id',this.userId)
        formData.append('file_url',this.fileUrl)
        formData.append('cate_id',this.add.cate_id)
        formData.append('title',this.add.title)
        formData.append('creater_care',this.add.creater_care)
        formData.append('paper_care',this.add.paper_care)
        formData.append('task_types', JSON.stringify(this.task_types))
        formData.append('total_score',this.add.total_score)
        formData.append('total_count',this.add.total_count)
        formData.append('limit_time',this.add.limit_time)
        formData.append('sort_type',this.add.sort_type)

        let content = {
          creater_care: this.add.creater_care,
          paper_care: this.add.paper_care,
          task_types: JSON.stringify(this.task_types),
          total_score: this.add.total_score,
          total_count: this.add.total_count,
          limit_time: this.add.limit_time,
          sort_type: this.add.sort_type
        }
        let formDataModel = new FormData()
        formDataModel.append('title',this.add.title)
        formDataModel.append('cate_id',this.add.cate_id)
        formDataModel.append('content',JSON.stringify(content))
        if(this.saveModel){
          addModel(formDataModel).then(res=>{
            this.$message.info('模板保存成功')
          },error =>{
            this.$message.info('模板保存失败')
          })
        }
        if(this.$route.query.id){
          formData.append('id', this.add.id)
          editTask(formData).then(res=>{
            if(res != 'underfind'){
              this.$router.push('/home/task')
            }
          }).catch(error=>{
            console.log(error)
          })
        }else{
          addTask(formData).then(res=>{
            if(res != 'underfind'){
              this.$router.push('/home/task')
            }
          }).catch(error=>{
            console.log(error)
          })
        }
      },
      Edit(item,index){
        this.showTaskType = true
        this.editTypes = item
        this.currentEditIndex = index
      },
      useModel({target}){
        if(target.checked){
          let {title, content} = this.modelData
          this.add.title = title
          this.add.creater_care = JSON.parse(content).creater_care
          this.add.paper_care = JSON.parse(content).paper_care
          this.add.total_score = JSON.parse(content).total_score
          this.add.total_count = JSON.parse(content).total_count
          this.add.sort_type = JSON.parse(content).sort_type
          this.add.limit_time = JSON.parse(content).limit_time
          this.task_types = JSON.parse(JSON.parse(content).task_types)
        }else{
          this.add.title = ''
          this.add.creater_care = ''
          this.add.paper_care = ''
          this.add.total_score = ''
          this.add.total_count = ''
          this.add.sort_type = ''
          this.add.limit_time = ''
          this.task_types = []
        }
      }
    },
};
</script>
<style lang="less" scoped>
.taskDrawer{
  padding: 30px;
  position: relative;
  .title{
    font-size: 18px
  }
}
.pl35x{
  padding-left: 35px
}

.inputBox{
  background: #fff;
  min-height: 82px;
  border-radius: 4px;
  padding:25px 50px;
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  &.grade{
    display: block;
    font-size: 16px;
    color: #999999;
  }
  >*{
    vertical-align: middle
  }
  .point{
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: #5180e0
  }
  .inputWrap{
    padding-left: 50px;
    flex: 1;
    /deep/ .ant-cascader-picker-label{
      font-size: 16px;
      height: 30px;
    }
  }
  .noBorder /deep/ .ant-input,  .noBorder /deep/ .ant-input:focus,.noBorder,.noBorder:focus,.noBorder /deep/ .ant-btn{
    font-size: 16px;
    border: none;
    box-shadow:none
  }
}
.flex2{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .inputBox{
    margin: 0;
    flex: 1;
  }
}
.ant-form-item{
  margin-bottom: 0;
  padding-bottom: 0
}
.gradeSet{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .point{
    margin-right: 50px;
    display: inline-block
  }
  .title{
    color: #999;
    font-size: 16px
  }
}
.gradeList{
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  &.mR0{margin-right: 0}
  >*{
    display: inline-block;
    vertical-align: middle;
  }
  >.title{
    width:120px;
    font-size: 16px;
    color: #999999;
      display: inline-block;
    vertical-align: middle;
  }
  .inputWrap{
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding-left: 0;
    position: relative;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 120px;
    padding-left: 10px;
    .rightTest{
      position: absolute;
      right: 0;
      width: 50px;
      font-size: 16px;
      color: #999999;
      top: 0;
      line-height: 40px;
      padding-right: 8px;
      text-align: right;
      &.w30x{
        width: 30px
      }
    }
  }
}
.hasText.ant-input{
  font-size: 16px;
  padding-right: 20px;
  height: 40px;
  line-height: 40px
}
.tText.hasText{
  padding-right: 50px
}
.addtaskType{
    padding: 0 20px;
    height: 35px;
    border-radius: 4px;
    cursor: pointer;
}
</style>